.homePage {
  //display: flex;
  //flex-direction: column;
  width: 100vw;
  height: calc(100vh - 50px);

  .map {
    flex-grow: 1;
    width: 100%;
    height: calc(100vh - 80px);

  }
}
