.header {
  height: 50px;
  background: royalblue;
  padding: 0 24px;
  display: flex;
  align-items: center;
  color: #FFF;
  user-select: none;

  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;

    svg {
      transition: 0.3s;
    }

    p {
      margin-left: 5px;
      color: #FFF;
      font-weight: bold;
      transition: 0.3s;
    }

    &:hover {
      svg {
        fill: lime;
      }

      p {
        color: lime;
      }

    }

  }

}
